import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Icon from "@identitybuilding/idb-react-iconlib";

import { useSelector, useDispatch } from "react-redux";
import React from "react";
import '../assets/css/SavedPage.css'
import axios from "axios";
import { removeFromSavedCMPs } from '../actions/index'


// function useForceUpdate() {
//   const [value, setValue] = useState(0); // integer state
//   return () => setValue(value => value + 1); // update state to force render
//   // An function that increment 👆🏻 the previous state like here 
//   // is better than directly setting `value + 1`
// }

const SavedPage = (props) => {
  // const { translate } = useSelector((state) => state.general);
  const { loginScreen , accountData} = useSelector(state => state.account);
  // const [sortParams, setSortParams] = useState(translate('recent_date'));
  
  // const forceUpdate = useForceUpdate();
  const dispatch = useDispatch()
  // const cmps = []

  

  const saveHandler = (cmp, index) => {
    if (accountData) {
        let copy = accountData.saved_cmps
        copy[index].saved = false
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("promotoken")}`,
          };
        axios.post(`https://management.100procentlokaal.be/core/api/catalogus/save_cmp/${cmp.id}/`,{
            headers: headers,
            token: localStorage.getItem("promotoken"),
            save_cmp: copy[index].saved
        }).then()

      dispatch(removeFromSavedCMPs(index))
      // forceUpdate();
  }
}

  return (
    <div id="savedpage" className={`${loginScreen ? 'blur' : ''}`}>
      <Navigation page="savedpage" />
      <div className="content">
        {accountData ?
           accountData.saved_cmps.length > 0 ?
          <React.Fragment>
            <div className="MagazineHeader">
              <h2 className="magazineButton">Opgeslagen magazines</h2>
              {/* <OsnSelect
                className="lang-select"
                onChange={(e) => console.log(e)}
                active={sortParams}
                options={[
                  { id: 0, name: translate('recent_date') },
                  { id: 1, name: translate('oldest_date') },
                  { id: 2, name: translate('a-z') },
                  { id: 3, name: translate('z-a') }
                ]}
              /> */}
            </div>

            <div className="cmp_collection_wrapper">
              {accountData.saved_cmps.filter(cmp => cmp.cmp_type === 'MAGAZINE').map((cmp, index) => (
                <div key={index} className="cmp_wrapper_pn">
                  <Icon name="FavoriteFill" className="saveIcon" onClick={() => saveHandler(cmp, index)}/>
                  <a className="myWrapper pn" target="_blank" rel="noreferrer" href={cmp[`cat_network_url`] || cmp.cat_network_url_nl || cmp.cat_network_url_fr || cmp.cat_network_url_de || cmp.cat_network_url_en}>
                    <div className="dd magazine">
                      <img src={cmp[`thumbnail`] || cmp.thumbnail_nl || cmp.thumbnail_fr || cmp.thumbnail_de || cmp.thumbnail_en} alt={cmp[`name`] || cmp.name_nl || cmp.name_fr || cmp.name_de || cmp.name_en}/>
                      <h4>{cmp[`name`] || cmp.name_nl || cmp.name_fr || cmp.name_de || cmp.name_en}</h4>
                      <div className="ddOverlay"></div>
                    </div>
                    <h5>
                      {cmp.valid_start}
                      <span>
                        <Icon name="Magazines" />
                      </span>
                      {cmp.valid_end}
                    </h5>
                  </a>
                </div>
              ))}
            </div>
          </React.Fragment>
          :
          <div className={`noCmps`}>
            <h3>Nog geen opgeslagen boekjes</h3>
            <figure>
              <img src={require('../assets/imgs/Plenny_wenen.png')} alt="Plenny being sad" />
            </figure>
          </div>
          :<div>Loading</div>
        }
      </div>
      <Footer />
    </div>
  );
};

export default SavedPage;
