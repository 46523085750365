
import { useSelector, useDispatch } from "react-redux";
import Icon from "@identitybuilding/idb-react-iconlib";
import React, { useEffect, useState } from "react";
import "../assets/css/FollowingConflict.css"
import "../assets/css/ShareButtons.css"
import { Button, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import { toggleOpenFollowingConflict } from '../actions/index'
import axios from 'axios'
import { EmailIcon, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon, } from "react-share";

const FollowingConflict = (props) => {
    const { translate, lang } = useSelector(state => state.general);
    const shareUrl = `https://www.catalogusnetwerk.be${window.location.pathname}`
    const [mail, setMail] = useState(false);
    const [fromMail, setFromMail] = useState("");
    const [error, setError] = useState("");
    const [toMails, setToMails] = useState([]);
    const [toMailText, setToMailText] = useState("");

    const copy_to_clipboard = (value) => {
        let copyText = document.location.href;
        let textArea = document.createElement('textarea');
        textArea.value = copyText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();

        props.createNotification("info", translate('copied_to_clipboard'))

    }

    // when pressing escape, this will close the login screen
    const keyDownHandler = (e) => {
        if (e.keyCode === 27) {
            props.setShare(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
    })


    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const addToMail = (e) => {
        let copy = [...toMails]
        if (isValidEmail(e)) {
            if (copy.includes(e)) {
            }
            else {
                copy.push(e)
            }
        } else {
            props.createNotification("warning", translate('not_a_valid_email'))

        }
        setToMails(copy)
        setToMailText("")
    }

    const removeToMail = (index) => {
        let copy = [...toMails]
        copy.splice(index, 1)
        setToMails(copy)
    }

    const toMailHandler = (e) => {
        if (e.keyCode === 13) {
            addToMail(e.target.value)
        }
    }

    const sendMail = () => {
        if (isValidEmail(fromMail)) {
            if (toMails.length > 0) {
                axios.post(`https://management.100procentlokaal.be/core/api/catalogus/send_share_mail/`, {
                    from_mail: fromMail,
                    to_mails: toMails,
                    site: "catalogusnetwerk",
                    lang: lang
                }).then((res) => {
                    props.setShare(false)
                    props.createNotification("info", translate('success'))
        })
            }
            else {
                setError("toMails")
            }
        }
        else {
            props.createNotification("warning", translate('not_a_valid_email'))
            setError("fromMail")
        }
    }



    let preview_nl = `<html style="font-family: Heebo;">
                    <body style="margin: 50px 130px">
                        <b style="color: black">${fromMail ? fromMail : "X"}</b> ontdekte het <a
                            href="https://www.catalogusnetwerk.be" target="_blank"
                            style="color: #1394d2; font-weight: 900; text-decoration: none">catalogusnetwerk.be</a>
                        en <a href="https://www.promonetwerk.be" target="_blank"
                            style="color: #9e3d97; font-weight: 900; text-decoration: none">promonetwerk.be</a>
                        met alle voordelen en wil dit met jou/jullie delen.<br /><br />

                        Meer info over alle voordelen kan je eerst hier bekijken: <br><br>
                        <img height="300"
                            src="https://catalogusnetwerk.be/static/media/cread-gebruikers.8e52f76935626986e577.png" />
                        <br><br>

                        ga naar <a style="text-decoration: none"
                            href="https://www.catalogusnetwerk.be" target="_blank">
                            <b style="color: #1394d2">https://www.catalogusnetwerk.be</b></a>
                        voor catalogi en/of magazines of <a style="text-decoration: none"
                            href="https://www.promonetwerk.be" target="_blank">
                            <b style="color: #9e3d97">https://www.promonetwerk.be</b></a> voor
                        promofolders en laat je verrassen!<br /><br />

                    </body>
                </html>`

    let preview_fr = ` <html style="font-family: Heebo;">

<body style="margin: 50px 130px; text-align:center">
    <b style="color: black">${fromMail ? fromMail : "X"}</b> a découvert le <a href="https://www.reseaucatalogue.be" target="_blank"
        style="color: #1394d2; font-weight: 900; text-decoration: none">reseaucatalogue.be</a>
    et <a href="https://www.reseaupromo.be" target="_blank"
        style="color: #9e3d97; font-weight: 900; text-decoration: none">reseaupromo.be</a>
    avec tous leurs avantages et souhaite les
    partager avec vous.<br /><br />

    Vous pouvez d'abord consulter plus d'informations sur tous les avantages
    ici :<br><br>
    <img height="300"
        src="https://www.reseaucatalogue.be/static/media/cread-gebruikers_fr.177717ba2bc8bc6b9301.png" />
    <br><br>

    Rendez-vous sur <a style="text-decoration: none"
        href="https://www.reseaucatalogue.be" target="_blank">
        <b style="color: #1394d2">https://www.reseaucatalogue.be</b></a>
    pour les catalogues et/ou magazines ou sur <a
        style="text-decoration: none" href="https://www.resaeupromo.be"
        target="_blank">
        <b style="color: #9e3d97">https://www.resaeupromo.be</b></a> pour
    les brochures promotionnelles et laissez-vous surprendre !<br /><br />

</body>

</html>`


    return (
        <div className="shareButtons">
            {mail ?
                <div className="container">
                    <Icon name="ArrowLongLeft" className="close back" onClick={() => setMail(false)} />
                    <h3>{translate('share_your_favorite_entrepreneur_with_friends_and_or_family')}</h3>
                    <OsnInputText error={error === 'fromMail' ? true : false} title={lang === 'nl' ? "Jouw email" : "Votre email"} value={fromMail} onChange={(e) => { setFromMail(e.target.value); setError('') }} />
                    <OsnInputText className="to_mails" error={error === 'toMails' ? true : false} title={lang === 'nl' ? "Email ontvanger(s)" : "Email récepteur(s)"} value={toMailText} onKeyDown={(e) => toMailHandler(e)} onChange={(e) => { setToMailText(e.target.value); setError('') }} cta_icon="Add" cta_click={() => addToMail(toMailText)} />
                    <p className="infoText"> {lang === 'nl' ? 'Vul een email in en druk op plus!' : 'Entrez un e-mail et appuyez sur plus!'} <br /> {lang === 'nl' ? 'Je kan ook meerdere email adressen toevoegen.' : 'Vous pouvez également ajouter plusieurs adresses e-mail.'} </p>
                    <div className="labels">
                        {toMails.map((mail, index) => (
                            <span className="label"> <Icon onClick={() => removeToMail(index)} name="Close" /> {mail}</span>
                        ))}
                    </div>
                    <Button
                        borderColor='cn'
                        text={`${translate('send')}`}
                        txtColor='cn'
                        type='sub'
                        size='S'
                        onClick={() => sendMail()}
                    />
                    <h3>{lang === 'nl' ? 'Bericht:' : 'Message :'}</h3>
                    <p className="message" dangerouslySetInnerHTML={{ __html: lang === 'nl' ? preview_nl : preview_fr }} />





                </div>
                :
                <div className="container">
                    <Icon name="Close" className="close" onClick={() => props.setShare(false)} />
                    <h3>{translate('share_your_favorite_entrepreneur_with_friends_and_or_family')}</h3>
                    {lang === 'nl' ?
                        <p>Kies een onderstaand platform om jouw <b>favoriete ondernemer</b> te delen!</p>
                        :
                        <p>Choisissez une plateforme ci-dessous pour partager votre <b>entrepreneur préféré</b> !</p>
                    }
                    <figure>
                        <img src={require('../assets/imgs/PLENNY FINAL_Tekengebied 1.svg').default} />
                    </figure>

                    <div className="buttons">
                        <FacebookShareButton
                            url={shareUrl}
                            className="shareButton facebook"
                        >
                            <FacebookIcon size={40} round />
                        </FacebookShareButton>

                        {/* ---------------------------------------------- */}

                        <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="shareButton messenger"
                        >
                            <FacebookMessengerIcon size={40} round />
                        </FacebookMessengerShareButton>

                        {/* ---------------------------------------------- */}

                        <TwitterShareButton
                            url={shareUrl}
                            title={'Ontdek alle voordelen van het Catalogusnetwerk'}
                            className="shareButton twitter"
                        >
                            <XIcon size={40} round />
                        </TwitterShareButton>
                        {/* ---------------------------------------------- */}
                        <WhatsappShareButton
                            url={shareUrl}
                            title={'Ontdek alle voordelen van het Catalogusnetwerk'}
                            separator=":: "
                            className="shareButton whatsapp"
                        >
                            <WhatsappIcon size={40} round />
                        </WhatsappShareButton>
                        {/* ---------------------------------------------- */}
                        <LinkedinShareButton
                            url={shareUrl}
                            className="shareButton linkedin"
                        >
                            <LinkedinIcon size={40} round />
                        </LinkedinShareButton>
                        {/* ---------------------------------------------- */}
                        {/* <EmailShareButton
                        url={shareUrl}
                        subject={'Ontdek alle voordelen van het Catalogusnetwerk'}
                        body=""
                        className="shareButton email"
                    > */}
                        <EmailIcon size={40} round className="shareButton email" onClick={() => setMail(true)} />
                        {/* </EmailShareButton> */}
                        {/* ---------------------------------------------- */}
                        <Icon name="Share" className="shareButton clipboard" onClick={() => copy_to_clipboard()} />
                    </div>

                </div>
            }
        </div>
    );
}

export default FollowingConflict;
