import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Icon from "@identitybuilding/idb-react-iconlib";

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import '../assets/css/ShoppingList.css'
import axios from "axios";
import { OsnCheckbox, OsnInputText, OsnAlertBox, Button } from "@identitybuilding/idb-react-ui-elements";


const ShoppingList = (props) => {
    const { loginScreen } = useSelector(state => state.account);
    const translate = useSelector(state => state.general.translate);
    const [data, setData] = useState([])
    const [original, setOriginal] = useState([])
    const [selectedList, setSelectedList] = useState(0)
    const [editTitle, setEditTitle] = useState(false)
    const [alertbox, setAlertbox] = useState(false)
    const [alsoInList, setAlsoInList] = useState(false)
    const [alsoInListItem, setAlsoInListItem] = useState("")
    const [alsoInListList, setAlsoInListList] = useState([])
    const [newProduct, setNewProduct] = useState("")

    useEffect(() => {
        getLists()

    }, [])

    const getLists = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("promotoken")}`,
        };
        axios.get(`https://management.100procentlokaal.be/core/api/catalogus/shoppinglists/`, {
            headers: headers,
            token: localStorage.getItem("promotoken"),
        }).then((res) => {
            setData(res.data.data)
            setOriginal(res.data.data)
        })
    }


    const updateShoppingList = (cmp, index) => {
            let copy = data
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("promotoken")}`,
            };
            axios.post(`https://management.100procentlokaal.be/core/api/catalogus/shoppinglists/update`, {
                headers: headers,
                token: localStorage.getItem("promotoken"),
                data: copy
            }).then()

        }

    const addProduct = () => {
        let copy = [...data]
        if (newProduct !== "") {
            copy[selectedList].items.push({
                name: newProduct,
                checked: false
            })
        }
        setNewProduct("")
        setData(copy)
        updateShoppingList()
    }

    const deleteProduct = (idx) => {
        let copy = [...data]

        const checkItem = copy[selectedList].items[idx];

        // Find and log the lists where the item is present
        const listsContainingItem = copy
            .filter((list, index) => list.title !== copy[selectedList].title && list.items.some(item => item.name === checkItem.name))
            .map(list => list.title);


        copy[selectedList].items.splice(idx, 1)


        if (listsContainingItem.length > 0) {
            setAlsoInList('delete')
            setAlsoInListItem(checkItem)
            setAlsoInListList(listsContainingItem)
        }


        setData(copy)
        updateShoppingList()
    }

    const checkHandler = (idx) => {
        let copy = [...data]

        copy[selectedList].items[idx].checked = !copy[selectedList].items[idx].checked

        const checkItem = copy[selectedList].items[idx];

        // Find and log the lists where the item is present
        const listsContainingItem = copy
            .filter((list, index) => list.title !== copy[selectedList].title && list.items.some(item => item.name === checkItem.name && item.checked !== checkItem.checked))
            .map(list => list.title);

        if (listsContainingItem.length > 0) {
            setAlsoInList('check')
            setAlsoInListItem(checkItem)
            setAlsoInListList(listsContainingItem)
        }
        setData(copy)
        updateShoppingList()
    }

    const deleteList = (index) => {
        let copy = [...data]
        copy.splice(selectedList, 1)
        setData(copy)
        setSelectedList(0)
        setNewProduct("")
        setAlertbox(false)
        updateShoppingList()
    }
    const addList = (index) => {
        let copy = [...data]
        copy.push({
            title: translate(`new_list`),
            items: []
        })
        setSelectedList(copy.length - 1)
        setNewProduct("")
        setData(copy)
        updateShoppingList()
    }
    const changeListTitle = (e) => {
        let copy = [...data]
        copy[selectedList].title = e
        setData(copy)
        updateShoppingList()
    }
    const originalTitle = () => {
        let copy = [...data]
        copy[selectedList].title = original[selectedList].title
        setData(copy)
    }

    const duplicateHandler = () => {
        let copy = [...data]
        const itemName = alsoInListItem.name;

        if (alsoInList === 'check') {
            // Check if the item exists in other lists and update their checked property
            copy.forEach((list, index) => {
                const otherListIndex = list.items.findIndex(item => item.name === itemName);
                if (otherListIndex !== -1) {
                    copy[index].items[otherListIndex].checked = alsoInListItem.checked;
                }
            });
        }
        if (alsoInList === 'delete') {
            copy = copy.map((list, index) => {
                if (list.items.some(item => item.name === itemName)) {
                    return {
                        ...list,
                        items: list.items.filter(item => item.name !== itemName)
                    };
                }
                return list;
            });
        }
        setData(copy)
        setAlsoInList(false)
        updateShoppingList()
    }


    return (
        <div id="shoppinglistpage" className={`${loginScreen ? 'blur' : ''}`}>
            {alertbox && <OsnAlertBox
                accept_text='Ja!'
                cancel_text="Nee, nog niet!"
                message={`Zeker dat je ${data[selectedList].title} wilt verwijderen`}
                onAccept={(e) => deleteList()}
                onDecline={(e) => setAlertbox(false)}
            />}

            {alsoInList && <OsnAlertBox
                accept_text='Ja'x
                cancel_text="Nee"
                message={`${alsoInListItem.name} is ook te vinden in volgende lijst(en): ${alsoInListList} . Wilt u deze ook aanpassen?`}
                onAccept={(e) => duplicateHandler()}
                onDecline={(e) => setAlsoInList(false)}
            />}
            <Navigation page="shoppinglistpage" />
            <div className="shoppinglist">
                <div className="sidebar">
                    <div className="sidebarItem" onClick={() => addList()}>
                        <Button
                            borderColor='cn'
                            text={translate(`new_list`)}
                            txtColor='cn'
                            type='sub'
                            size='S'
                        />
                    </div>
                    {data.map((res, index) => (
                        <div key={index} onClick={() => { setSelectedList(index); index !== selectedList && setEditTitle(false) }} className={`sidebarItem ${index === selectedList ? 'active' : ''}`}>
                            {editTitle === index ?
                                <OsnInputText placeholder={res.title} value={res.title} onChange={(e) => changeListTitle(e.target.value)} />
                                :
                                <span style={{ marginRight: "auto" }}>{res.title}</span>
                            }
                            {editTitle === index ?
                                <>
                                    <Icon name="Check" onClick={() => setEditTitle(false)} />
                                    <Icon name="Close" onClick={() => { setEditTitle(false); originalTitle() }} />
                                </>
                                :
                                <>
                                    <Icon name="Edit" onClick={() => setEditTitle(index)} />
                                    <Icon name="Bin" onClick={() => setAlertbox(true)} />
                                </>
                            }

                        </div>
                    ))}
                </div>
                <div className="shoppinglist_content">
                    <div className="container">
                        <h2>{data.length > 0 && data[selectedList].title}</h2>
                        <div className="list_item header">
                            <OsnInputText placeholder={translate("add_a_product")} value={newProduct} onChange={(e) => setNewProduct(e.target.value)} />
                            <Icon name="Add" onClick={() => addProduct()} />
                        </div>
                        {data.length > 0 && data[selectedList].items.map((item, index) => (
                            <div className="list_item" key={index}>
                                <OsnCheckbox name={item.name} value={item.name} checked={item.checked} onChange={() => checkHandler(index)} />
                                <Icon onClick={() => deleteProduct(index)} name="Close" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ShoppingList;
