import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios'

import { Button, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import Icon from "@identitybuilding/idb-react-iconlib";
import Navigation from "../components/Navigation"
import ShareButtons from "../components/ShareButtons"
import Footer from "../components/Footer"
import '../assets/css/DetailsPage.css'
import Slider from "react-slick";
import { removeFollowingEstablishment, toFollowEst, toggleLoginScreen, toggleOpenFollowingConflict } from '../actions/index'

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

const DetailsPage = (props) => {
    const translate = useSelector((state) => state.general.translate);
    const { loginScreen, accountData } = useSelector(state => state.account);
    const lang = useSelector((state) => state.general.lang);
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();

    const [data, setData] = useState("");
    const [CMPData, setCMPData] = useState("");
    const [cmpsCollections, setCmpsCollections] = useState([]);
    const [bpIndex, setBpIndex] = useState("all");
    const [menu, setMenu] = useState(false);
    const [share, setShare] = useState(false);
    const [followers, setFollowers] = useState("");

    const [menuItems, setMenuItems] = useState([]);
    const [sortParams, setSortParams] = useState(translate('recent_date'));


    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        dots: false,
        infinite: true,
        initialSlide: 0,
        lazyload: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 500,
        variableWidth: true,
        pauseOnhover: true,
        swipe: false,
    };

    useEffect(() => {
        // if (accountData) {
        let search_id = ""
        let query = " "
        let option = {
            from: 0,
            size: 30,
            query: {
                query_string: {
                    query: query,
                    fields: [
                        'slug',
                        'name_nl',
                        'name_fr',
                        'name_de',
                        'name_en',
                        'alt_name_nl',
                        'alt_name_fr',

                    ],
                    tie_breaker: 0.3,
                },
            },
        };
        axios.get(`https://search-osn-management-hkfarflgp5aj2vbhfzvmyycuuy.eu-central-1.es.amazonaws.com/_search?q=search_id:${props.match.params.id}`,
            {
                auth: {
                    username: `${process.env.REACT_APP_ESUSERNAME}`,
                    password: `${process.env.REACT_APP_ESPSW}`,
                },
                params: {
                    source_content_type: "application/json",
                    source: JSON.stringify(option),
                },
            }
        ).then((res) => {
            let data = res.data.hits.hits[0]._source
            search_id = data.number

            axios.get(`https://management.100procentlokaal.be/core/api/business/${search_id}/cmps/?lang=${lang}`,
                {
                    auth: {
                        username: `${process.env.REACT_APP_ESUSERNAME}`,
                        password: `${process.env.REACT_APP_ESPSW}`,
                    },
                    params: {
                        source_content_type: "application/json",
                        source: JSON.stringify(option),
                        token: localStorage.getItem("promotoken"),
                    },
                }
            ).then((res) => {
                setFollowers(res.data.catalogus_followers)
                data.establishment_name = data[`name_${lang}`] || data.name_nl || data.name_fr || data.name_de || data.name_en
                data.municipality = data.address[`municipality_${lang}`] || data.address.municipality_nl || data.address.municipality_fr || data.address.municipality_de || data.address.municipality_en
                data.municipality = data.municipality.replace('(Ville)', '').replace('(Stad)', '')
                data.slug = data.address.slug
                data.id = data.search_id
                data.following = false
                if (accountData && accountData.followingestablishments_set.some(est => est === data.number)) { data.following = true }

                let copy_cmps = []
                let menu = []
                let cmp_data = res.data
                cmp_data.establishment_cmp_collections.forEach((collection) => {
                    collection.cmp_collection.cmps.forEach((cmp) => {
                        if (cmp.cmp_type === "MAGAZINE") {
                            cmp.saved = false
                            if (accountData && accountData.saved_cmps.some(adata => adata.id === cmp.id)) { cmp.saved = true }
                            copy_cmps.push(cmp)
                        }

                    })
                    if (copy_cmps.length > 0) {
                        menu.push(collection.cmp_collection.name)
                    }
                })
                cmp_data.enterprise_cmp_collections.forEach((collection) => {
                    collection.cmp_collection.cmps.forEach((cmp) => {
                        if (cmp.cmp_type === "MAGAZINE") {
                            cmp.saved = false
                            if (accountData && accountData.saved_cmps.some(adata => adata.id === cmp.id)) { cmp.saved = true }
                            copy_cmps.push(cmp)
                        }

                    })
                    if (copy_cmps.length > 0) {
                        menu.push(collection.cmp_collection.name)
                    }
                })
                cmp_data.group_cmp_collections.forEach((collection) => {
                    collection.cmp_collection.cmps.forEach((cmp) => {
                        if (cmp.cmp_type === "MAGAZINE") {
                            cmp.saved = false
                            if (accountData && accountData.saved_cmps.some(adata => adata.id === cmp.id)) { cmp.saved = true }
                            copy_cmps.push(cmp)
                        }

                    })
                    if (copy_cmps.length > 0) {
                        menu.push(collection.cmp_collection.name)
                    }
                })
                // data.cmp_collectionlinks.forEach((collection) => {
                //     collection.cmps.forEach((cmp) => {
                //         if (cmp.cmp_type === "PROMO") {
                //             cmp.saved = false
                //             if (accountData && accountData.saved_cmps.some(data => data.thumbnail === cmp[`thumbnail_${lang}`])) { cmp.saved = true }
                //             copy_cmps.push(cmp)
                //         }

                //     })
                //     if (copy_cmps.length > 0) {
                //         menu.push(collection[`name_${lang}`] || collection.name_nl || collection.name_fr || collection.name_de || collection.name_en)
                //     }
                // })
                // data.cmp_collectionlinks.forEach((collection) => {
                //     collection.cmps.forEach((cmp) => {
                //         if (cmp.cmp_type === "PROMO") {
                //             cmp.saved = false
                //             if (accountData && accountData.saved_cmps.some(data => data.thumbnail === cmp[`thumbnail_${lang}`])) { cmp.saved = true }
                //             copy_cmps.push(cmp)
                //         }

                //     })
                //     if (copy_cmps.length > 0) {
                //         menu.push(collection[`name_${lang}`] || collection.name_nl || collection.name_fr || collection.name_de || collection.name_en)
                //     }
                // })

                data.logos = []
                data.logolinks.forEach((logo) => {
                    let logoItem = logo[`png_${lang}`] || logo.png_nl || logo.png_fr || logo.png_de || logo.png_en
                    data.logos.push(logoItem)
                })

                let sorted_cmps = copy_cmps.sort(function (a, b) {
                    let afrom = a.valid_start.split('-')
                    let bfrom = b.valid_start.split('-')
                    let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                    let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                    // sort on recent date
                    if (adate > bdate) { return -1 }
                    if (adate < bdate) { return 1 }


                });
                setMenuItems(menu)
                setCmpsCollections(sorted_cmps)
                setData(data)
                setCMPData(cmp_data)
            })


        })

        // }
    }, [accountData, followers])


    const saveHandler = (cmp, index, type) => {
        if (accountData) {
            let copy = cmpsCollections
            let item = ""
            if (type === "followers") {
                copy.filter((item) => item.following === true)[index].saved = !copy.filter((item) => item.following === true)[index].saved
                item = copy.filter((item) => item.following === true)[index]
            }
            else {
                copy.filter((item) => item.following === false)[index].saved = !copy.filter((item) => item.following === false)[index].saved
                item = copy.filter((item) => item.following === false)[index]
            }
            setCmpsCollections(copy)

            forceUpdate();

            const headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("promotoken")}`,
            };
            axios.post(`https://management.100procentlokaal.be/core/api/catalogus/save_cmp/${cmp.id}/`, {
                headers: headers,
                token: localStorage.getItem("promotoken"),
                save_cmp: item.saved
            }).then(() => {
                props.getData()
            }).catch(() => props.getData())
        }

    }

    const followHandler = () => {
        if (accountData) {
            let accountCopy = accountData
            if (accountCopy.followingestablishments_set.includes(data.number)) {
                accountCopy.followingestablishments_set = accountCopy.followingestablishments_set.filter(e => e !== data.number);
            }
            else {
                accountCopy.followingestablishments_set.push(data.number);
            }
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("promotoken")}`,
            };
            axios.post(`https://management.100procentlokaal.be/core/api/catalogus/follow_establishment/${data.number}/?lang=${lang}`, {
                headers: headers,
                token: localStorage.getItem("promotoken"),
                following: !data.following
            }).then(() => {
                let copy = data
                copy.following = !copy.following
                setData(copy)
                dispatch(removeFollowingEstablishment(accountCopy))
                if (data.following) { setFollowers(followers + 1) }
                else { setFollowers(followers - 1) }
                forceUpdate();

            }).catch((err) => {
                if (err.response.data.Status === 'Duplicate group') {
                    err.response.data.establishment.current_est = data
                    dispatch(toggleOpenFollowingConflict(err.response.data.establishment))
                }
            })
        }
        else {
            dispatch(toFollowEst(data.number))
            dispatch(toggleLoginScreen(!loginScreen))
            props.createNotification("warning", translate('please_log_in_first'))
        }
    }

    const changeSort = (e) => {
        setSortParams(e.name)
        let sorted_cmps = []
        let copy_cmps = cmpsCollections

        sorted_cmps = copy_cmps.sort(function (a, b) {
            let afrom = a.valid_start.split('-')
            let bfrom = b.valid_start.split('-')
            let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
            let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
            // // sort on recent date
            // if (adate > bdate) { return -1 }
            // if (adate < bdate) { return 1 }
            if (e.name === translate("recent_date")) {
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }
            }
            if (e.name === translate("oldest_date")) {
                if (adate > bdate) { return 1 }
                if (adate < bdate) { return -1 }
            }
            if (e.name === translate("a-z")) {
                if ((a.name_nl > b.name_nl) || (a.name_fr > b.name_fr) || (a.name_de > b.name_de) || (a.name_en > b.name_en)) { return 1 }
                if ((a.name_nl < b.name_nl) || (a.name_fr < b.name_fr) || (a.name_de < b.name_de) || (a.name_en < b.name_en)) { return -1 }
            }

            // sort unalphabetical
            if (e.name === translate("z-a")) {
                if ((a.name_nl > b.name_nl) || (a.name_fr > b.name_fr) || (a.name_de > b.name_de) || (a.name_en > b.name_en)) { return -1 }
                if ((a.name_nl < b.name_nl) || (a.name_fr < b.name_fr) || (a.name_de < b.name_de) || (a.name_en < b.name_en)) { return 1 }
            }

        });

        setCmpsCollections(sorted_cmps)
        forceUpdate();
    }

    const changeCollection = (index) => {
        let collections = CMPData.establishment_cmp_collections.concat(CMPData.enterprise_cmp_collections, CMPData.group_cmp_collections);
        let sorted_cmps = []
        let copy_cmps = []
        if (index === 'all') {
            collections.forEach((collection) => {
                // data.cmp_collectionlinks.forEach((collection) => {
                collection.cmp_collection.cmps.forEach((cmp) => {
                    if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }

                })
            })
            sorted_cmps = copy_cmps.sort(function (a, b) {
                let afrom = a.valid_start.split('-')
                let bfrom = b.valid_start.split('-')
                let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                // sort on recent date
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }


            });

        }
        else {

            collections[index].cmp_collection.cmps.forEach((cmp) => {
                // data.cmp_collectionlinks[index].cmps.forEach((cmp) => {
                if (cmp.cmp_type === "MAGAZINE") { copy_cmps.push(cmp) }
            })

            // sort
            sorted_cmps = copy_cmps.sort(function (a, b) {
                let afrom = a.valid_start.split('-')
                let bfrom = b.valid_start.split('-')
                let adate = new Date(afrom[2], Number(afrom[1] - 1), afrom[0])
                let bdate = new Date(bfrom[2], Number(bfrom[1] - 1), bfrom[0])
                // sort on recent date
                if (adate > bdate) { return -1 }
                if (adate < bdate) { return 1 }


            });
        }
        setCmpsCollections(sorted_cmps)
        setBpIndex(index)

    }

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
    };

    return (
        data &&
        <div id="detailPage" className={`${loginScreen ? 'blur' : ''}`}>
            <Navigation {...props} page="resultspage" />
            {share && <ShareButtons createNotification={props.createNotification} setShare={(e) => setShare(e)}/>}
            <div id="detailContent" className={`${cmpsCollections.length > 0 ? '' : 'empty'}`}>
                <Icon onClick={() => setMenu(true)} className="hamburgerIcon" name="MenuFill" />
                <div className={`detailSidebar ${menu ? 'active' : ''}`}>
                    <Icon onClick={() => setMenu(false)} className="closeIcon" name="Close" />
                    <div className="logo">
                        {data.logos.length > 1 ?
                            <Slider {...settings}>
                                {data.logos.map((res, i) => (
                                    <figure key={i} >
                                        <img src={res} />
                                    </figure>
                                ))}
                            </Slider>
                            :
                            <img src={data.logos[0]} />
                        }
                    </div>
                    <div className="info">
                        <h3 title={data.establishment_name}>{data.establishment_name}</h3>
                        {/* <Button
                            borderColor='pn'
                            text={translate('follow')}
                            txtColor='pn'
                            type='sub'
                            size='S'
                        /> */}
                        <div className="sideNavButtons">
                        {data && <div className={`following_button ${data.following ? 'following' : ''}`} onClick={() => followHandler()}>
                            {data.following ? translate('following') : translate('follow')}
                        </div>}
                        {data && <div className={`following_button`} onClick={() => setShare(true)}>
                           {translate('share')}
                        </div>}
                        </div>
                        {<span className="followers">({followers} {translate('followers')})</span>}
                        
                    </div>
                    <ul>
                        <li className="active">
                            <a onClick={() => changeCollection('all')} className={`${bpIndex === 'all' ? 'active' : ''}`}>{translate('info_and_magazines')}</a>
                            <div className="linkChildren">
                                {menuItems.length > 1 &&
                                    menuItems.map((item, index) => (
                                        <span className={`${bpIndex === index ? 'active' : ''}`} key={index} onClick={() => changeCollection(index)}>{item}</span>

                                    ))
                                }
                            </div>
                        </li>
                        <li>
                            <a className="osn" target="_blank" href={`https://${data.slug}.${lang === 'nl' ? '100procentlokaal' : '100pourcentlocale'}.be/business/${data.id}/${data.establishment_name}/contact`}>{translate('business_page')}</a>
                        </li>
                    </ul>
                    <div className="buttonText">
                        {/* <span><Icon name="Info" /> {translate('search_for_all_enterprises')} {data.municipality}</span> */}
                        <Button
                            borderColor='cn'
                            text={`${translate('go_to')} 100% ${lang === 'nl' ? 'lokaal' : 'locale'}`}
                            txtColor='cn'
                            type='sub'
                            size='S'
                            url={`https://${data.address.postal_code}.${lang === 'nl' ? '100procentlokaal' : '100pourcentlocale'}.be`}
                        />
                    </div>
                </div>
                <div className="detailsMain">
                    {cmpsCollections.length > 0 ?
                        <React.Fragment>
                            {/* {bpIndex !== "all" ? <h2>{menuItems[bpIndex]}</h2> : <h2></h2>} */}
                            <h2></h2>
                            <div className="MagazineHeader">
                                <span className="magazineButton">Promo's</span>
                                <OsnSelect
                                    className="lang-select"
                                    onChange={(e) => changeSort(e)}
                                    active={sortParams}
                                    options={[
                                        { id: 0, name: translate('recent_date') },
                                        { id: 1, name: translate('oldest_date') },
                                        { id: 2, name: translate('a-z') },
                                        { id: 3, name: translate('z-a') }
                                    ]}
                                />
                            </div>

                            {accountData && accountData.followingestablishments_set.includes(data.number) && cmpsCollections.filter((item) => item.following === true).length > 0 && <div className="cmp_collection_wrapper exclusive">
                                <span className="followers_text">{translate('exclusive_for_followers')}</span>
                                {cmpsCollections.filter((item) => item.following === true).map((cmp, index) => (
                                    <div key={index} className={`cmp_wrapper_pn`}>
                                        {accountData && <Icon name={cmp.saved ? "FavoriteFill" : "Favorite"} className="saveIcon" onClick={() => saveHandler(cmp, index)} />}
                                        <a className="myWrapper pn" target="_blank" href={cmp.cat_network_url || cmp[`cat_network_url_${lang}`] || cmp.cat_network_url_nl || cmp.cat_network_url_fr || cmp.cat_network_url_de || cmp.cat_network_url_en}>
                                            <div className="dd magazine">
                                                <img src={cmp.thumbnail || cmp[`thumbnail_${lang}`] || cmp.thumbnail_nl || cmp.thumbnail_fr || cmp.thumbnail_de || cmp.thumbnail_en} />
                                                <h4>{cmp.name || cmp[`name_${lang}`] || cmp.name_nl || cmp.name_fr || cmp.name_de || cmp.name_en}</h4>
                                                <div className="ddOverlay"></div>
                                            </div>
                                            <h5 style={{ "textTransform": "uppercase", "justifyContent": "center", "display": "flex", "color": "var(--cn)" }}>{translate('next_period')}{cmp.edition_number && ` - ${cmp.edition_number}`}</h5>
                                            <h5>
                                                {cmp.valid_start || cmp[`valid_start_${lang}`] || cmp.valid_start_nl || cmp.valid_start_fr || cmp.valid_start_de || cmp.valid_start_en}
                                                <span>
                                                    <Icon name="Magazines" />
                                                </span>
                                                {cmp.valid_end || cmp[`valid_end_${lang}`] || cmp.valid_end_nl || cmp.valid_end_fr || cmp.valid_end_de || cmp.valid_end_en}
                                            </h5>
                                        </a>
                                    </div>
                                ))}
                            </div>}
                            <div className="cmp_collection_wrapper">
                                {cmpsCollections.filter((item) => item.following === false).map((cmp, index) => (
                                    <div key={index} className={`cmp_wrapper_pn ${cmp.following ? 'exclusive' : ''}`}>
                                        {accountData && <Icon name={cmp.saved ? "FavoriteFill" : "Favorite"} className="saveIcon" onClick={() => saveHandler(cmp, index)} />}

                                        <a className="myWrapper pn" target="_blank" href={cmp.cat_network_url || cmp[`cat_network_url_${lang}`] || cmp.cat_network_url_nl || cmp.cat_network_url_fr || cmp.cat_network_url_de || cmp.cat_network_url_en}>
                                            {cmp.following && <span className="followers_text">{translate('exclusive_for_followers')}</span>}
                                            <div className="dd magazine">
                                                <img src={cmp.thumbnail || cmp[`thumbnail_${lang}`] || cmp.thumbnail_nl || cmp.thumbnail_fr || cmp.thumbnail_de || cmp.thumbnail_en} />
                                                <h4>{cmp.name || cmp[`name_${lang}`] || cmp.name_nl || cmp.name_fr || cmp.name_de || cmp.name_en}</h4>
                                                <div className="ddOverlay"></div>
                                            </div>

                                            {new Date() >= parseDate(cmp.valid_start) && new Date() <= parseDate(cmp.valid_end) && <h5 style={{ "textTransform": "uppercase", "justifyContent": "center", "display": "flex", "color": "var(--cn)" }}>{translate('this_period')}{cmp.edition_number && ` - ${cmp.edition_number}`}</h5>}
                                            <h5>
                                                {cmp.valid_start || cmp[`valid_start_${lang}`] || cmp.valid_start_nl || cmp.valid_start_fr || cmp.valid_start_de || cmp.valid_start_en}
                                                <span>
                                                    <Icon name="Magazines" />
                                                </span>
                                                {cmp.valid_end || cmp[`valid_end_${lang}`] || cmp.valid_end_nl || cmp.valid_end_fr || cmp.valid_end_de || cmp.valid_end_en}
                                            </h5>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                        :
                        <div className={`noCmps ${data.is_city ? 'city' : ''}`}>
                            <h3>Deze ondernemer heeft geen boekjes</h3>
                            <figure>
                                <img src={require('../assets/imgs/Plenny_wenen.png')} alt="Plenny being sad" />
                            </figure>
                        </div>
                    }
                </div>
            </div>
            <Footer {...props} />
        </div>
    )
}

export default DetailsPage