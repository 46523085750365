import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios'

// import DigitalDisplay from "@identitybuilding/idb-react-digital-display";
// import { Button, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
// import Icon from "@identitybuilding/idb-react-iconlib";

// 1435

import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Searchbar from "../components/Searchbar"
import Pagination from "../components/Pagination";
import '../assets/css/Resultspage.css'
import { Button } from "@identitybuilding/idb-react-ui-elements";
import Slider from "react-slick";
import Icon from "@identitybuilding/idb-react-iconlib";
import { removeFollowingEstablishment } from '../actions/index'


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const FollowingPage = (props) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState("")
  const [totalCount, setTotalCount] = useState("")
  const { lang, translate } = useSelector(state => state.general);
  const [paginationData, setPaginationData] = useState({})
  const { loginScreen, accountData } = useSelector(state => state.account);
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();

  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    dots: false,
    infinite: true,
    initialSlide: 0,
    lazyload: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
    pauseOnhover: true,
    swipe: false,
  };


  useEffect(() => {
    if (accountData) {        // window.addEventListener('resize', handleResize)
      setLoading(false)
      let query = props.match.params.keyword ? props.match.params.keyword : ""
      // Pagination -----------------------------------
      let pagination = { current_page: props.match.params.page }
      let from = 0;
      let size = 30;
      if (pagination.current_page !== 1) { from = (pagination.current_page - 1) * 30 }
      if (window.innerWidth < 1435 && window.innerWidth > 1135) {
        size = 28
        from = (pagination.current_page - 1) * 28
      }
      // ----------------------------------------------
      axios.get(`https://search-osn-management-hkfarflgp5aj2vbhfzvmyycuuy.eu-central-1.es.amazonaws.com/_search?q=number:${accountData.followingestablishments_set.toString()}`,
        {
          auth: {
            username: `${process.env.REACT_APP_ESUSERNAME}`,
            password: `${process.env.REACT_APP_ESPSW}`,
          },
          params: {
            // source_content_type: "application/json",
            // source: JSON.stringify(options),
          },
        }
      )
        .then((res) => {
          let copy = []

          res.data.hits.hits.map((item) => {
            let newItem = {}
            newItem.name = item._source[`name_${lang}`] || item._source.name_nl || item._source.name_fr || item._source.name_de || item._source.name_en
            newItem.enterprise_name = item._source.from_enterprise[`official_name_${lang}`] || item._source.from_enterprise.official_name_nl || item._source.from_enterprise.official_name_fr || item._source.from_enterprise.official_name_de || item._source.from_enterprise.official_name_en
            newItem.logos = []
            newItem.id = item._source.search_id
            newItem.number = item._source.number
            newItem.following = false
            if (accountData && accountData.followingestablishments_set.some(est => est === item._source.number)) { newItem.following = true }
            newItem.promocount = 0
            item._source.cmp_collectionlinks.map((collection) => {
              collection.cmps.map((cmp) => {
                if (cmp.cmp_type === 'MAGAZINE') {
                  newItem.promocount = newItem.promocount + 1
                }
              })
            })

            item._source.logolinks.map((logo) => {

              let logoItem = logo[`png_${lang}`] || logo.png_nl || logo.png_fr || logo.png_de || logo.png_en
              newItem.logos.push(logoItem)

            })
            copy.push(newItem)
          })
          let total_pages = Math.ceil(res.data.hits.total.value / 30);
          setTotalCount(res.data.hits.total.value)
          let copy_pages = {
            current_page: pagination.current_page,
            next_page: Number(pagination.current_page) + 1,
            prev_page: Number(pagination.current_page) - 1,
            total_pages: total_pages,
          };
          setPaginationData(copy_pages)
          setData(copy);
          setLoading(true)
        })
    }

  }, [accountData])

  const followHandler = (index) => {
    if (accountData) {
        let accountCopy = accountData
        if (accountCopy.followingestablishments_set.includes(data[index].number)) {
            accountCopy.followingestablishments_set = accountCopy.followingestablishments_set.filter(e => e !== data[index].number);
        } else {
            accountCopy.followingestablishments_set.push(data[index].number);
        }
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("promotoken")}`,
        };
        axios.post(`https://management.100procentlokaal.be/core/api/catalogus/follow_establishment/${data[index].number}/?lang=nl`, {
            headers: headers,
            token: localStorage.getItem("promotoken"),
            following: !data[index].following
        }).then(() => {
            let copy = data
            copy[index].following = !copy[index].following
            dispatch(removeFollowingEstablishment(accountCopy))
            setData(copy)
            forceUpdate();

        }).catch((err) => {
            if (err.response.data.Status === 'Duplicate group') {
                // err.response.data.establishment.current_est = data[index]
                // dispatch(toggleOpenFollowingConflict(err.response.data.establishment))
            }
        })
    } else {
        // dispatch(toggleLoginScreen(!loginScreen))
        props.createNotification("warning", translate('please_log_in_first'))
    }
}



  return (
    accountData &&
    <div id="resultspage" className={`${loginScreen ? 'blur' : ''}`}>
      <Navigation page="resultspage" />
      <div className="content">
        <div className="card_environment">
          {loading && data.length > 0 &&
            <div className="pagination">
              <b>
                {translate("page")} {paginationData.current_page} -{" "}
                {paginationData.total_pages}:{" "}
              </b>
              {translate("with") + " "}
              {totalCount}{" "}
              {translate(
                data.length > 1 || data.length === 0
                  ? "results"
                  : "result"
              )}
            </div>
          }
          {loading ?
            data.length > 0 ? data.map((item, index) => (
              <div key={index} className="card">
                <figure>
                  {item.logos.length > 1 ?
                    <Slider {...settings}>
                      {item.logos.map((res, i) => (
                        <figure key={i} >
                          <img src={res} />
                        </figure>
                      ))}
                    </Slider>
                    :
                    <figure>
                      <img src={item.logos[0]} />
                    </figure>
                  }
                </figure>
                <div className="card_info">
                  <div style={{ "minHeight": "43px" }}>
                    <h2 title={item.name} >{item.name}</h2>
                    {item.enterprise_name && item.name.toLowerCase() !== item.enterprise_name.toLowerCase() && <h3 title={item.enterprise_name} >{item.enterprise_name}</h3>}
                  </div>
                  <h5>{item.promocount} {item.promocount > 1 ? translate('magazines') : 'Magazine'}</h5>
                  <div className="card_buttons">
                    {/* <Button
                                            borderColor='pn'
                                            text={`${translate('follow')}`}
                                            txtColor='pn'
                                            type='sub'
                                            size='S'
                                        /> */}
                    {<div className={`following_button ${item.following ? 'following' : ''}`} onClick={() => followHandler(index)}>
                      {item.following ? 'Volgend' : translate('follow')}
                    </div>}
                    <Link to={`/business/${item.id}`}>
                      <Button
                        borderColor='cn'
                        text={`${translate('discover_more')}`}
                        txtColor='cn'
                        type='sub'
                        size='S'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))
              :
              <div className="errorMessage">
                <span>{translate("no_results")}</span>
              </div>
            :
            // <Icon className="loading_icon" name="Loading" animated />
            <div className={`noCmps`}>
              <h3>Nog geen gevolde ondernemers</h3>
              <figure>
                <img src={require('../assets/imgs/Plenny_wenen.png')} alt="Plenny being sad" />
              </figure>
            </div>
          }
        </div>
        {paginationData && paginationData.total_pages !== 0 &&
          <Pagination pages={paginationData} keyword={props.match.params.keyword} />
        }
      </div>
      <Footer />
    </div >
  )
}

export default FollowingPage