import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Searchbar from "../components/Searchbar";
import SearchLastCriteria from "../components/SearchLastCriteria";
import '../assets/css/Home.css'

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import Icon from "@identitybuilding/idb-react-iconlib";

const Main = (props) => {
  const lang = useSelector((state) => state.general.lang);
  const { loginScreen, accountData } = useSelector(state => state.account);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`https://management.100procentlokaal.be/core/api/catalogus/latest_cmps/?lang=${lang}&promo_type=magazine`)
      .then((res) => {
        setData(res.data)
      })
  }, [])

  const Arrow = (props) => {
    const { className, onClick, dir } = props
    return (
        <Icon
            className={className}
            name={dir === "next" ? "ArrowRight" : "ArrowLeft"}
            onClick={onClick}
        />
    )
}

  const settings = {
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: false,
    dots: false,
    infinite: true,
    initialSlide: 0,
    lazyload: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <Arrow dir="next" />,
    prevArrow: <Arrow dir="prev" />,
    variableWidth: true,
    pauseOnhover: true,
    swipe: false,
  };


  const saveHandler = (cmp, index) => {
    // if (accountData) {
    //     let copy = cmpsCollections
    //     copy[index].saved = !copy[index].saved
    //     setCmpsCollections(copy)

    //     forceUpdate();
    //     // myArray = myArray.filter(function( obj ) {
    //     //     return obj.id !== id;
    //     //   });
    //     const headers = {
    //         "Content-Type": "application/json",
    //         Authorization: `Token ${localStorage.getItem("promotoken")}`,
    //       };
    //     axios.post(`https://management.100procentlokaal.be/core/api/catalogus/save_cmp/${cmp.id}/`,{
    //         headers: headers,
    //         token: localStorage.getItem("promotoken"),
    //         save_cmp: copy[index].saved
    //     }).then(() => {
    //         props.getData()
    //     })
    //     .catch(() => props.getData())
    // }

  }

  return (
    <div id="homepage" className={`${loginScreen ? 'blur' : ''}`}>
      <Navigation page="homepage" />
      <div className="content">
        <div className="search_engine">
          <figure>
            <img src={require(`../assets/imgs/logos/cn/catalogusnetwerk-h-${lang}.svg`)} alt="logo of catalogus" />
          </figure>
          <Searchbar />
          <SearchLastCriteria />
        </div>
          <div id="recent_folders">
            <h3>{lang === 'nl' ? 'Meest recente' : 'Le plus récent'}</h3>
            <Slider {...settings}>
              {data.length > 0 && data.map((cmp, index) => (
                <div key={index} className="cmp_wrapper_pn" >
                  {accountData && <Icon name={cmp.saved ? "FavoriteFill" : "Favorite"} className="saveIcon" onClick={() => saveHandler(cmp, index)} />}
                  <a className="myWrapper pn" target="_blank" href={cmp.cat_network_url} >
                    <div className="dd magazine">
                      <img src={cmp.thumbnail !== "https://zappa-tlaqv351d.s3.amazonaws.com/media/" ? cmp.thumbnail : require('../assets/imgs/logos/logo_placeholder_vertical.png')} />
                      <h4>{cmp.name}</h4>
                      <div className="ddOverlay"></div>
                    </div>
                    <h5>
                      {cmp.valid_start}
                      <span>
                        <Icon name="Magazines" />
                      </span>
                      {cmp.valid_end}
                    </h5>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
      </div>
      <Footer />
    </div>
  )
}

export default Main